import { Requisites } from '@/api/companyService.types';
import { Id, Nullable } from '@/types';
import { CurrencyCode } from '@/utils';
import { ProposalPositionType } from '@/shared/constants/proposalPosition';
import { ProposalExtraOptions } from '../Request/components/ResponseExtraOptions/model';
import { PurchaseResponseStatuses } from './PurchaseResponseStatuses';

export const PurchaseRequestStatuses = {
  draft: 'draft',
  receivingResponses: 'receiving_responses',
  receptionCompleted: 'reception_completed',
  archived: 'archived',
  onApproval: 'on_approval',
  onIntroduction: 'on_introduction',
  denialOfApproval: 'denial_of_approval',
  forPublication: 'for_publication',
  agreed: 'agreed',
  notified: 'notified',
  canceled: 'canceled',
  completed: 'completed',
  scheduled: 'scheduled',
  underModeration: 'under_moderation'
};

export enum ProcurementStatus {
  DRAFT = 'draft',
  RECEIVING_RESPONSES = 'receiving_responses',
  RECEPTION_COMPLETED = 'reception_completed',
  ARCHIVED = 'archived',
  ON_APPROVAL = 'on_approval',
  ON_INTRODUCTION = 'on_introduction',
  DENIAL_OF_APPROVAL = 'denial_of_approval',
  FOR_PUBLICATION = 'for_publication',
  AGREED = 'agreed',
  NOTIFIED = 'notified',
  CANCELED = 'canceled',
  COMPLETED = 'completed',
  SCHEDULED = 'scheduled',
  UNDER_MODERATION = 'under_moderation',
  MODERATION_FAILED = 'moderation_failed'
}

export enum ProposalStatus {
  DRAFT = 'draft',
  SENT = 'sent',
  VIEWED = 'viewed',
  REQUEST_CANCELED = 'request-canceled',
  REJECTED = 'rejected',
  CLARIFICATION = 'clarification',
  ACCEPTED = 'accepted',
  REWORK = 'on-rework',
  REWORK_COMPLETED = 'rework-completed',
  REQUEST_INVOICE = 'invoice-request',
  INVOICED = 'invoiced',
  ON_APPROVAL = 'on_approval',
  ON_INTRODUCTION = 'on_introduction',
  DENIAL_OF_APPROVAL = 'denial_of_approval',
  NOTIFIED = 'notified',
  AGREED = 'agreed',
  COMPLETED = 'completed',
  CANCELED = 'canceled'
}

export const PurchaseRequestConfirmedStatus = [
  ProcurementStatus.DRAFT,
  ProcurementStatus.RECEIVING_RESPONSES,
  ProcurementStatus.RECEPTION_COMPLETED,
  ProcurementStatus.COMPLETED,
  ProcurementStatus.CANCELED,
  ProcurementStatus.SCHEDULED
] as const;

export enum ExecutorActionType {
  AGREEMENT = 'agreement',
  INTRODUCTION = 'introduction',
  PUBLICATION = 'publication'
}

export enum ProcurementType {
  REGULAR = 'Regular',
  REGULAR_WITH_CONFIRMATION = 'RegularWithConfirmation',
  AUCTION = 'Auction',
  AUCTION_WITH_CONFIRMATION = 'AuctionWithConfirmation'
}

export type AuctionType =
  | ProcurementType.AUCTION
  | ProcurementType.AUCTION_WITH_CONFIRMATION;

export enum AuctionRankType {
  PRICE = 'price',
  PRICE_AND_TIMESTAMP = 'priceAndTimestamp'
}

export enum PurchaseRequestLsKeys {
  RANK_TYPE = 'rank-type',
  VAT_INCLUDED = 'vat-included',
  REQUEST_TYPE = 'pr-type'
}

interface AuthorizedCompany {
  id: number;
  name: string;
}

export interface PurchaseRequestProduct {
  type?: ProposalPositionType;
  id: string;
  name: string;
  count: string;
  unit: string;
  price?: number;
}

export interface ResponsibleUserInfo {
  id: number;
  firstName: string;
  lastName: string;
  middleName: string;
  employeeId: number;
}

interface Supplier {
  date: string;
  companyId: number;
}

interface Executor {
  id: number;
  employeeId: number;
  firstName: string;
  lastName: string;
  middletName: string;
  requestId: number;
  routeId: number;
  step: unknown;
  actionType: unknown;
  status: unknown;
}

export interface ResponseProduct {
  id: string;
  sum: number;
  name: string;
  unit: string;
  count: string;
  price: number;
  initPrice: string;
  originalName: string;
  response?: {
    canBeSelected: boolean;
  };
}

export interface RequestFile {
  id: string;
  name: string;
  type: string;
  fileId: string;
  linkId: Nullable<string>;
  section: string;
  fileInfo: FileInfo;
  companyId: number;
  createdAt: string;
  updatedAt: string;
}

interface FileInfo {
  id: string;
  url: string;
  name: string;
  size: number;
  type: string;
  viewedBy: number[];
  companyId: number;
  createdAt: string;
  updatedAt: string;
  dimensions: unknown;
  originalUrl: string;
}

export interface ResponseFile {
  id: string;
  name: string;
  type: string;
  extension: string;
  size: number;
  url: string;
  createdAt: string;
  fileInfo?: FileInfo;
}

interface Link {
  id: number;
  name: string;
  url: string;
}

interface Criterion {
  type: 'openEnded' | 'closeEnded';
  multiple?: boolean;
  allowFiles?: boolean;
  criterion: string;
  options?: Array<string>;
  selectedOptions?: Array<number>;
  files?: Array<any>;
  answer?: string;
}

interface AttrsPurchaseRequest {
  id: number;
  text: string;
  type: string;
  files: ResponseFile[];
  links: Link[];
  products: ResponseProduct[];
  requestType: ProcurementType;
  respondedUser: number;
  nonPriceCriteria: Criterion[];
}

interface ResponseAttrs {
  type: string;
  toName: string;
  toEmail: string;
  fromName: string;
  products: unknown[];
  fromEmail: string;
  purchaseRequest: AttrsPurchaseRequest;
  cargo?: {
    shopId: number;
    dimensions: {
      height: number;
      width: number;
      length: number;
      weight: number;
    };
  };
}

interface Invoice {
  id: number;
  companyId: number;
  name: string;
  originalUrl: string;
  url: string;
  size: number;
  type: unknown;
  dimensions: unknown;
  createdAt: string;
  updatedAt: string;
  viewedBy: unknown;
}

interface CompanyInfo {
  rating: Nullable<number>;
  reliability: Nullable<number>;
  qualified: boolean;
}

interface AuctionInfo {
  autoRenewalPeriod?: number;
  decreaseRate?: number;
  disableBidEdit: boolean;
  preBidAllowed: boolean;
  factualEndDate?: string;
  isSupplierBidHidden: boolean;
  rankType: AuctionRankType;
}

export interface PurchaseRequestResponse {
  id: number;
  uuid: string;
  from: number;
  to: number;
  status: PurchaseResponseStatuses;
  attrs: ResponseAttrs;
  createdAt: string;
  updatedAt: string;
  fromPurchaseRequest: boolean;
  requestId: number;
  request: unknown;
  orderNumber: number;
  isArchived: boolean;
  extraOptions: Nullable<ProposalExtraOptions>;
  invoiceId: Nullable<number>;
  invoice: Nullable<Invoice>;
  autoReject: boolean;
  confirmation: Nullable<unknown>;
  companyInfo: Nullable<CompanyInfo>;
  external: boolean;
  respondedEmployeeId: number;
  respondedEmployeeInfo: Nullable<unknown>;
  primaryPartner: Nullable<number>;
  acceptedAt: string;
  qualified: boolean;
  currency: CurrencyCode;
  finalizationRequested?: boolean;
  manualProposal: boolean;
  text?: string;
}

export interface Currency {
  code: CurrencyCode;
  rate?: Nullable<number>;
  modified?: boolean;
  updatedAt?: string;
  base?: boolean;
}

type SupplierId = Id;
type ProductId = string;
type Price = number;

type PriceProduct = Record<ProductId, Price>;
type SpecificPrices = Record<SupplierId, PriceProduct>;
type SpecificCurrencies = Record<SupplierId, CurrencyCode>;

export interface SupplierSpecificPrices {
  prices: SpecificPrices;
  currencies: SpecificCurrencies;
}

export interface Address {
  id: string;
  value: string;
}

export interface PurchaseRequest {
  id: number;
  type: ProcurementType;
  routeId: Nullable<number>;
  categoriesId: number[];
  address: Nullable<Address>;
  country: string;
  region: string;
  city: string;
  productsList: string;
  products: PurchaseRequestProduct[];
  partResponse: boolean;
  supplierRequirements: unknown;
  attachments: RequestFile[];
  companyId: number;
  responsibleUser: ResponsibleUserInfo;
  responsibleUserInfo: Nullable<ResponsibleUserInfo>;
  sum: Nullable<number>;
  responseStartDate: Nullable<string>;
  responseEndDate: string;
  links: unknown[];
  title: string;
  number: string;
  accessCompanyList: Nullable<number[]>;
  suppliers: Supplier[];
  lastPublishedDate: string;
  status: ProcurementStatus;
  bookmarkedBy: number[];
  createdAt: string;
  isArchived: boolean;
  responses: PurchaseRequestResponse[];
  viewedBy: number[];
  updatedAt: string;
  authorId: number;
  auctionInfo: AuctionInfo | null;
  authorInfo?: unknown;
  executors: Executor[];
  executorType?: ExecutorActionType;
  accreditationQuestionnaireId: Nullable<number>;
  authorizedCompanies?: AuthorizedCompany[];
  companyInfo: {
    id: number;
    inn: string;
    name: string;
    ogrn: string;
    ownerUserId: number;
    requisites: Requisites;
    qualified: boolean;
  };
  responded?: PurchaseRequestResponse;
  nonPriceCriteria: Criterion[];
  vatIncluded: boolean;
  accreditationRequired?: boolean;
  contactInfo?: Nullable<{ phoneNumber: string; email: string }>;
  channelInfo?: {
    id: string;
    isJoined: boolean;
  };
  currencies: Currency[];
  currency: CurrencyCode;
  finalizeAt: string;
  haveSpecificPrices?: boolean;
  supplierSpecificPrices: SupplierSpecificPrices;
  alphaRequestId?: Nullable<Id>;
  parentRequestId?: Nullable<Id>;
  childRequestId?: Nullable<Id>;
  stage?: Nullable<number>;
  latestStageId: Nullable<Id>;
}
