import {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { TextField, Popper, InputAdornment } from '@mui/material';
import { ClickAwayListener } from '@mui/base';
import { add, startOfDay, isValid, format, parse } from 'date-fns';
import cn from 'classnames';
import { useIMask, IMask } from 'react-imask';
import { isFunction } from '@/lib/utils';
import { isFirstDateGT } from '@/lib/date';
import { Typography } from '@/components/Typography';
import { ButtonIcon } from '@/components/ButtonIcon';
import { LocaleTypes } from '@/components/common.types';
import { TimePickerProps } from './TimePicker.types';
import styles from './TimePicker.module.scss';

const DIVIDER = ':';
const RU_PLACEHOLDER = `чч${DIVIDER}мм`;
const EN_PLACEHOLDER = `hh${DIVIDER}mm`;

const FORMAT = `HH${DIVIDER}mm`;

const LOCALE_PLACEHOLDERS: Record<LocaleTypes, string> = {
  ru: RU_PLACEHOLDER,
  en: EN_PLACEHOLDER
};

const newDate = new Date(2023, 4, 21);

interface CustomAdornmentProps {
  onClick: () => void;
  disabled: boolean;
}

interface TimeStampProps {
  timeStamp: Date;
  onSelectTime: (time: Date) => void;
}

function getTimeIntervals(
  initialDate: Date,
  limited: boolean,
  interval: number
) {
  const startOfTheDay = startOfDay(initialDate);
  const timeStampArray = new Array((24 * 60) / interval)
    .fill(null)
    .map((_value, index) => add(startOfTheDay, { minutes: interval * index }));
  if (!limited) {
    return timeStampArray;
  }

  return timeStampArray.filter((value) => value >= initialDate);
}

const transformToStr = (timeStamp: Date) =>
  `${
    timeStamp.getHours() < 10
      ? `0${timeStamp.getHours()}`
      : timeStamp.getHours()
  }${DIVIDER}${
    timeStamp.getMinutes() < 10
      ? `0${timeStamp.getMinutes()}`
      : timeStamp.getMinutes()
  }`;

const transformToMask = (timeStamp: Date | string | number | null) => {
  if (!timeStamp) {
    return '';
  }

  const date = new Date(timeStamp);

  if (!isValid(date)) {
    return '';
  }

  return `${date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()}${
    date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()
  }`;
};
const convertTime = (time: Date | number | string) => {
  if (typeof time === 'number' || typeof time === 'string') {
    return new Date(time);
  }
  return time;
};

const getTimeValue = (time: Date | number | string | null) =>
  time ? new Date(time).getTime() : null;

const setInitialTime = (initialTime: Date | string | number | null) =>
  initialTime ? convertTime(initialTime) : newDate;

function CustomAdornment({ onClick, disabled }: CustomAdornmentProps) {
  return (
    <InputAdornment position="end">
      <ButtonIcon
        disabled={disabled}
        iconName="alarm"
        variant="small"
        className={styles.alarm}
        onClick={onClick}
      />
    </InputAdornment>
  );
}

function TimeStamp({ timeStamp, onSelectTime }: TimeStampProps) {
  const handleChange = () => onSelectTime(timeStamp);
  return (
    <Typography
      variant="body1Reg"
      className={styles.timeStamp}
      onClick={handleChange}>
      {transformToStr(timeStamp)}
    </Typography>
  );
}

export function TimePicker({
  className,
  disabled = false,
  onTimeChange,
  locale = 'ru',
  initialTime = null,
  limited = false,
  interval = 30,
  valid = true
}: TimePickerProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [open, setOpen] = useState(false);
  const [timeValue, setTimeValue] = useState(getTimeValue(initialTime));
  const [error, setError] = useState(false);
  const handleOpening = useCallback(() => setOpen(true), []);
  const handleClickAway = useCallback(() => setOpen(false), []);

  const timeRef = useRef(
    getTimeIntervals(setInitialTime(initialTime), limited, interval)
  );

  const maskOptions = {
    mask: Date,
    pattern: FORMAT,
    overwrite: false,
    lazy: false,
    format: (date: Date) => {
      if (!date) {
        return FORMAT;
      }
      return format(date, FORMAT);
    },
    parse: (str: string) => parse(str, FORMAT, Date.now()),
    blocks: {
      HH: {
        mask: IMask.MaskedRange,
        from: 0,
        to: 23,
        placeholderChar: locale === 'ru' ? 'ч' : 'h'
      },
      mm: {
        mask: IMask.MaskedRange,
        from: 0,
        to: 59,
        placeholderChar: locale === 'ru' ? 'м' : 'm'
      }
    }
  };

  const {
    ref,
    value: displayValue,
    setUnmaskedValue
  } = useIMask(maskOptions, {
    onAccept: (value) => {
      const intValue = parseInt(value.replace(DIVIDER, ''), 10);

      if (!value || Number.isNaN(intValue)) {
        setError(false);
        setTimeValue(null);
        onTimeChange?.({
          time: null,
          reason: null
        });
      } else if (
        value.length < 4 + DIVIDER.length ||
        intValue.toString().length < 4
      ) {
        setError(true);
      }
    },
    onComplete: (value) => {
      const time = setInitialTime(initialTime);
      const [hh, mm] = value.split(':');
      time.setHours(+hh, +mm);

      setError(
        limited && initialTime
          ? isFirstDateGT(new Date(initialTime), time)
          : false
      );
      setTimeValue(getTimeValue(time));

      onTimeChange?.({
        time,
        reason: null
      });
    }
  });

  const isEmptyInput =
    !displayValue || displayValue === LOCALE_PLACEHOLDERS[locale];

  useEffect(() => {
    setTimeValue(getTimeValue(initialTime));
    setUnmaskedValue(transformToMask(initialTime));
    setError(false);
  }, [initialTime]);

  useEffect(() => {
    timeRef.current = getTimeIntervals(
      setInitialTime(initialTime),
      limited,
      interval
    );
  }, [limited, initialTime, interval]);

  const handleBlur = useCallback(() => {
    setUnmaskedValue(transformToMask(timeValue));

    if (timeValue) {
      setError(
        limited && initialTime
          ? isFirstDateGT(new Date(initialTime), timeValue)
          : false
      );
    } else {
      setError(false);
    }
  }, [initialTime, limited, setUnmaskedValue, timeValue]);

  const onSelectTime = useCallback(
    (newTime: Date) => {
      if (isFunction(onTimeChange)) {
        onTimeChange({ time: newTime, reason: null });
      }
      setTimeValue(getTimeValue(newTime));
      setUnmaskedValue(transformToMask(newTime));
      setOpen(false);
      setError(false);
    },
    [onTimeChange, setUnmaskedValue]
  );

  const popperStyles: CSSProperties = useMemo(() => {
    if (anchorEl) {
      return {
        width: anchorEl?.offsetWidth || 0 - 34
      };
    }
    return {};
  }, [anchorEl]);

  return (
    <div
      className={cn(
        className,
        styles.timePicker,
        disabled && styles.disabled,
        (error || !valid) && styles.error
      )}
      data-testid="time-picker">
      <div ref={(node) => setAnchorEl(node)}>
        <Typography variant="body1Reg" Component="div">
          <TextField
            inputRef={ref}
            error={error || !valid}
            disabled={disabled}
            onBlur={handleBlur}
            InputProps={{
              endAdornment: (
                <CustomAdornment onClick={handleOpening} disabled={disabled} />
              ),
              inputProps: {
                'data-testid': 'input',
                spellCheck: 'false',
                className: cn({ [styles.placeholder]: isEmptyInput })
              }
            }}
            placeholder={LOCALE_PLACEHOLDERS[locale]}
          />
        </Typography>
      </div>
      {open && (
        <ClickAwayListener onClickAway={handleClickAway}>
          <Popper
            anchorEl={anchorEl}
            open={open}
            style={popperStyles}
            className={styles.popperIndex}>
            <div className={styles.popper}>
              <div className={styles.list}>
                {timeRef.current.map((timeStamp) => (
                  <TimeStamp
                    timeStamp={timeStamp}
                    key={Number(timeStamp)}
                    onSelectTime={onSelectTime}
                  />
                ))}
              </div>
            </div>
          </Popper>
        </ClickAwayListener>
      )}
    </div>
  );
}
